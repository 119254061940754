import { BreakpointWidths } from '@pelotoncycle/design-system';
import type { NextPage } from 'next';
import dynamic from 'next/dynamic';
import React, { useEffect, useRef, useState } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { ALLOW_COPY_XRAY } from '@peloton/app-config';
import { blueBlack } from '@peloton/colors';
import { NextMetadataTags } from '@peloton/next/components/MetadataTags';
import toGetStaticProps from '@peloton/next/data/toGetStaticProps';
import { useLocale } from '@peloton/next/hooks/useLocale';
import { SkipLinkToMain, Main } from '@acme-ui/global/skip-links';
import { CollapsibleTopNav } from '@acme-ui/nav/CollapsibleTopNav/CollapsibleTopNav';
import { MobileNav } from '@acme-ui/nav/MobileNav/MobileNav';
import Nav from '@acme-ui/nav/Nav';
import useNextPage from '@content/client/hooks/useNextPage';
import CartPanel from '@ecomm/cart-next/panel/Panel';
import { useIsNavRefactorEnabled } from '@ecomm/feature-toggle/NavRefactor';
import {
  useIsNextFullPageExperiment,
  useFullPageExperimentViewedLog,
} from '@ecomm/full-page-experiments/browser';
import { useWindowSize } from '@ecomm/hooks/useWindowSize';
import PbxNav from '@ecomm/nav/Nav';
import type { TypePage } from '@page-builder/lib/types';
import BannerOrVariation from '@page-builder/modules/Banner/BannerOrVariation';
import BlockProvider from '@page-builder/modules/Block/BlockProvider';
import NextBlock from '@page-builder/modules/Block/NextBlock';
import { toBannerProps, toPageData } from '@page-builder/utils/pages';

const DEFAULT_TITLE =
  'Peloton® | Exercise Bike With Indoor Cycling Classes Streamed Live & On-Demand';

const CopyXray = dynamic(() => import('@ecomm/copy-xray/CopyXray'), {
  ssr: false,
});

const Footer = dynamic(() => import('@acme-ui/footer'), {
  ssr: false, // Needs access to window for one trust
});
const EcommPage = dynamic(() => import('@page-builder/layout/NextEcommPage'));

type PageProps = {
  page: TypePage;
};

const isHomepageSlug = (slug: string = '') => decodeURIComponent(slug) === '/';

// Force build next-www for Apollo 3 prod test some more
const Homepage: NextPage<PageProps> = ({ page: fallbackPage }) => {
  const isFullPageExperiment = useIsNextFullPageExperiment('www');
  const page = useNextPage(fallbackPage, isFullPageExperiment);
  const {
    title,
    keywords,
    description,
    pageTypeId,
    slug,
    imageUrl,
    id,
    pageContent,
    name,
    noIndex,
  } = toPageData(page);

  // We need to check the page slug to work around an issue with full page experiments, where a homepage test
  // ends up switching from the [...slugs] page to this index page on the client
  const isHomepage = isHomepageSlug(slug);

  // We also don't want to track the full page experiment data if the page being rendered is not the homepage,
  // meaning that we've switched from  [...slugs] to the homepage on the client
  useFullPageExperimentViewedLog('www', !isHomepage);

  const { Track } = useTracking({
    parent: pageTypeId,
  });
  const locale = useLocale();
  const windowSize = useWindowSize();

  const [bannerHeight, setBannerHeight] = useState(0);
  const [collapsibleTopNavHeight, setCollapsibleTopNavHeight] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const bannerRef = useRef(null);
  const MainComponent = isMobile ? MainWithNavBackground : Main;
  const isNavRefactorEnabled = useIsNavRefactorEnabled();
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const observer = new ResizeObserver(entries => {
      setBannerHeight(entries[0]?.target?.clientHeight);
    });

    if (bannerRef.current) {
      observer.observe(bannerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    // to prevent the flickering offset on the initial page load.
    if (windowSize.width < BreakpointWidths.tablet) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSize.width]);

  if (!pageContent) {
    return <div></div>;
  }

  const { banner: bannerProps, hideBanner } = toBannerProps(page);

  return (
    <>
      <NextMetadataTags
        title={title || DEFAULT_TITLE}
        keywords={keywords || []}
        description={description || ''}
        locale={locale}
        slug={slug}
        imageUrl={imageUrl}
        noIndex={noIndex}
      />
      <BlockProvider>
        <Track>
          {ALLOW_COPY_XRAY && <CopyXray />}

          {!hideBanner && (
            <div ref={bannerRef}>
              <BannerOrVariation data={bannerProps} />
            </div>
          )}
          <SkipLinkToMain />
          {isNavRefactorEnabled ? (
            <PbxNav />
          ) : isMobile ? (
            <CollapsibleTopNav
              offSetScrollHeight={bannerHeight}
              onContainerHeightChange={setCollapsibleTopNavHeight}
            >
              <Nav transparent={false} sticky={true} />
              <MobileNav />
            </CollapsibleTopNav>
          ) : (
            <Nav transparent={isHomepage} />
          )}
          <MainComponent collapsibleTopNavHeight={collapsibleTopNavHeight}>
            <EcommPage
              name={name}
              key={`${pageTypeId}-${id}`}
              {...pageContent.fields}
              block={NextBlock}
            />
          </MainComponent>
          <CartPanel />
          <Footer locale={locale} />
        </Track>
      </BlockProvider>
    </>
  );
};

export default Homepage;

export const getStaticProps = toGetStaticProps({
  toPageSlug: () => '/',
});

export const MainWithNavBackground = styled(Main)<{ collapsibleTopNavHeight: number }>`
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: -${props => props.collapsibleTopNavHeight}px;
    height: ${props => props.collapsibleTopNavHeight}px;
    width: 100vw;
    background-color: ${blueBlack};
    z-index: -1;
  }
`;
